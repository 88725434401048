import React, { useState } from "react";
//Redux
import { useDispatch } from "react-redux";
import { setUserLogged, setUserRol, setComponentActive } from "../../../reducers/userConfigSlice";
import Logo from "./../../../assets/logo/logo.jpg";
import { RiCloseFill, RiCheckFill } from "react-icons/ri";
import "./style.css";
import { Input } from "../../../components/inputs/type1";

export const PinSelect = () => {
  const dispatch = useDispatch();
  const [pinValue, setPinValue] = useState("");
  const pinList = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    <RiCloseFill color={"var(--color-error)"} />,
    "0",
    <RiCheckFill color={"var(--color-success)"} />,
  ];

  const handleUser = async () => {
    dispatch(setComponentActive("commander"));
  };

  const handleDigit = (id, pin) => {
    console.log("handleDigit " + id, pin);
    if (id < 9 || pin === 10) setPinValue(pinValue + pin);
    if (id === 9) setPinValue("");
    if (id === 11) handleUser();
  };

  const CardPin = ({ pin, id }) => {
    return (
      <div className="cardPin" onClick={() => handleDigit(id, pin)}>
        <div className="pinDigit">{pin}</div>
      </div>
    );
  };

  return (
    <div className="areaPin">
      <div className="loginArea">
        <div className="topSide">
          <div className="logoContainer">
            <img src={Logo} alt="logo" className="logoLogin" />
          </div>
        </div>
        <div className="containerSide">
          <div className="titleLogin">Ingrese su pin</div>
          <div>
            <Input
              value={pinValue}
              id={"pinInput"}
              type={"text"}
              placeholder=""
              disabled={""}
              onBlur={() => null}
              icon={null}
              iconType={""}
              classname={"m-3 text-align-center"}
            />
          </div>
          <div className="pinList">
            {pinList.map((pin, id) => (
              <CardPin pin={pin} id={id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
