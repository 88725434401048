import { useState, useEffect } from "react";
//Redux
//Components
import { FiHome } from "react-icons/fi";
import { Button } from "./../../components/button";
import { Shop } from "./pages/shop";
//Functions
//Styles
import "./style.css";

export const ConfigurationPanel = ({ title, content, hasButtonSave = true, action = () => null }) => {
  return (
    <div className="adminPanel mb-2 h-auto">
      <div className="adminPanelContent">
        <div className="configurationPanelContentTitle">
          {title}
          {hasButtonSave && <Button key={"Save"} text={"Guardar"} icon={<FiHome />} action={action} customClass={"customButtonColor2"} />}
        </div>
        {content}
      </div>
    </div>
  );
};

export const SalesChannel = () => {
  const [selectedButton, setSelectedButton] = useState(100);
  const [content, setContent] = useState();

  const handleMenu = (option) => {
    setSelectedButton(option.id);
    setContent(option.content);
  };

  const blockMenu = [
    {
      id: 100,
      name: "Tienda Online",
      icon: <FiHome />,
      content: <Shop />,
    },
    {
      id: 101,
      name: "WhatsApp",
      icon: <FiHome />,
      content: <Shop />,
    },
    {
      id: 102,
      name: "Globo",
      icon: <FiHome />,
      content: <Shop />,
    },
  ];

  const MenuDesign = ({ block }) => {
    return block.map((option) => {
      const activeStyle = option.id === selectedButton ? " optionMenuActive " : "";
      return (
        <div onClick={() => handleMenu(option)} className={"configurationMenu " + activeStyle}>
          <div className="configurationMenuIcon">{option.icon}</div>
          <div style={{ paddingTop: "4px" }}>{option.name}</div>
        </div>
      );
    });
  };

  useEffect(() => {
    handleMenu(blockMenu[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bodyComponent">
      <div className="row w-100">
        <div style={{ width: "250px" }}>
          <div className="row mb-3">
            <div className="col-12" style={{ padding: "0" }}>
              <div className="adminPanel">
                <div className="adminPanelContent">
                  <div className="adminPanelContentTitle">Canales de venta</div>
                  <MenuDesign block={blockMenu} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row mb-3">
            <div className="col-12">{content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
