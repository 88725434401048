import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Sections
import Login from "./pages/login";
import { PinSelect } from "./pages/login/userSelect/pin";
import { UserSelect } from "./pages/login/userSelect";
import { Commander } from "./pages/commander";
import { ProtectedRoute } from "./components/security/protectedRoute";
import { SalesChannel } from "./pages/salesChannel";
import { History } from "./pages/history";
import { Header } from "./pages/header";
//Functions
//import useLanguage from "./functions/useLanguage";

function App() {
  //useLanguage();
  const logged = useSelector((state) => state.aplicationConfig.userConfig.logged);
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);

  const Component = () => {
    if (logged && logged) {
      switch (componentActive) {
        case "userSelect":
          return <UserSelect />;
        case "pinSelect":
          return <PinSelect />;
        case "commander":
          return (
            <>
              <Header />
              <Commander />
            </>
          );
        case "salesChannel":
          return (
            <>
              <Header />
              <SalesChannel />
            </>
          );
        case "history":
          return (
            <>
              <Header />
              <History />
            </>
          );

        default:
          break;
      }
    } else return <Login />;
  };

  return <Component />;
}

export default App;
