import React from "react";
//Components
import { Input } from "../../../../components/inputs/type1";
import { GoMail } from "react-icons/go";

export const FormRecover = () => {
  const handleRecover = () => {
    return true;
  };

  return (
    <div>
      <p className="labelLogin">Email</p>
      <Input id={"emailInput"} type={"text"} placeholder="" disabled={""} onBlur={() => null} icon={<GoMail />} classname={""} />

      <button className="button-1" onClick={handleRecover}>
        Enviar
      </button>
    </div>
  );
};
