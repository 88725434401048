import React, { useState } from "react";
//Redux
import { useDispatch } from "react-redux";
import { setUserLogged, setUserRol, setComponentActive } from "../../../reducers/userConfigSlice";
import Logo from "./../../../assets/logo/logo.jpg";
import { LuUser2 } from "react-icons/lu";
import "./style.css";

export const UserSelect = () => {
  const dispatch = useDispatch();
  const userList = [
    { id: 0, name: "Alberto" },
    { id: 1, name: "Sara" },
  ];

  const handleUser = async () => {
    //navigate("/pinSelect");
    dispatch(setComponentActive("pinSelect"));
  };

  const CardUser = ({ data }) => {
    return (
      <div className="cardUser" onClick={handleUser}>
        <div className="userIcon">
          <LuUser2 />
        </div>
        <div className="username">{data.name}</div>
      </div>
    );
  };

  return (
    <div className="loginArea">
      <div className="topSide">
        <div className="logoContainer">
          <img src={Logo} alt="logo" className="logoLogin" />
        </div>
        <div className="titleLogin">Seleccione un usuario</div>
      </div>
      <div className="userList">
        {userList.map((user) => (
          <CardUser data={user} />
        ))}
      </div>
    </div>
  );
};
