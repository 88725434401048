export const dataOrdersSalesChannel = [
  {
    pedido: "#001",
    cliente: "Juan Perez",
    quantity: 1,
    total: 50.25,
    estadoPago: 3,
  },
  {
    pedido: "#002",
    cliente: "Juan Perez",
    quantity: 4,
    total: 43.25,
    estadoPago: 3,
  },
  {
    pedido: "#003",
    cliente: "Juan Perez",
    quantity: 3,
    total: 10.25,
    estadoPago: 3,
  },
  {
    pedido: "#004",
    cliente: "Juan Perez",
    quantity: 6,
    total: 25.25,
    estadoPago: 3,
  },
];

export const dataOrdersHistory = [
  {
    pedido: "#001",
    cliente: "Juan Perez",
    canal: "Tienda en línea",
    estadoPago: 4,
    total: 50.25,
    estadoPreparacion: 1,
  },
  {
    pedido: "#002",
    cliente: "Maria Garcia",
    canal: "Teléfono",
    estadoPago: 4,
    total: 75.6,
    estadoPreparacion: 1,
  },
  {
    pedido: "#003",
    cliente: "Carlos Sanchez",
    canal: "Tienda física",
    estadoPago: 4,
    total: 120.0,
    estadoPreparacion: 2,
  },
  {
    pedido: "#004",
    cliente: "Ana Lopez",
    canal: "WhatsApp",
    estadoPago: 4,
    total: 90.75,
    estadoPreparacion: 3,
  },
  {
    pedido: "#005",
    cliente: "Luis Martinez",
    canal: "Tienda en línea",
    estadoPago: 3,
    total: 35.5,
    estadoPreparacion: 2,
  },
  {
    pedido: "#006",
    cliente: "Laura Rodriguez",
    canal: "Correo electrónico",
    estadoPago: 2,
    total: 65.2,
    estadoPreparacion: 1,
  },
  {
    pedido: "#007",
    cliente: "Pedro Gomez",
    canal: "Teléfono",
    estadoPago: 4,
    total: 110.0,
    estadoPreparacion: 1,
  },
];

export const data = [
  {
    pedido: "#001",
    cliente: "Juan Perez",
    canal: "Tienda en línea",
    entrega: "Domicilio",
    estadoPago: 4,
    total: 50.25,
    estadoPreparacion: 1,
    fecha: "2024-03-17 11:53:22",
  },
  {
    pedido: "#002",
    cliente: "Maria Garcia",
    canal: "Teléfono",
    entrega: "Domicilio",
    estadoPago: "Pendiente",
    total: 75.6,
    estadoPreparacion: "Pendiente",
    fecha: "2024-03-17 11:50:22",
  },
  {
    pedido: "#003",
    cliente: "Carlos Sanchez",
    canal: "Tienda física",
    entrega: "Local",
    estadoPago: 4,
    total: 120.0,
    estadoPreparacion: 2,
    fecha: "2024-03-17 11:44:22",
  },
  {
    pedido: "#004",
    cliente: "Ana Lopez",
    canal: "WhatsApp",
    entrega: "Domicilio",
    estadoPago: 4,
    total: 90.75,
    estadoPreparacion: 3,
    fecha: "2024-03-17 11:28:22",
  },
  {
    pedido: "#005",
    cliente: "Luis Martinez",
    canal: "Tienda en línea",
    entrega: "Local",
    estadoPago: "Pagado",
    total: 35.5,
    estadoPreparacion: "No preparado",
    fecha: "2024-03-17 11:20:22",
  },
  {
    pedido: "#006",
    cliente: "Laura Rodriguez",
    canal: "Correo electrónico",
    entrega: "Domicilio",
    estadoPago: "Pendiente",
    total: 65.2,
    estadoPreparacion: "Pendiente",
    fecha: "2024-03-17 11:18:22",
  },
  {
    pedido: "#007",
    cliente: "Pedro Gomez",
    canal: "Teléfono",
    entrega: "Local",
    estadoPago: "Pendiente",
    total: 110.0,
    estadoPreparacion: "Pendiente",
    fecha: "2024-03-17 11:17:22",
  },
  {
    pedido: "#008",
    cliente: "Sofia Diaz",
    canal: "WhatsApp",
    entrega: "Domicilio",
    estadoPago: "Pagado",
    total: 80.3,
    estadoPreparacion: "En preparación",
    fecha: "2024-03-17 11:16:22",
  },
  {
    pedido: "#009",
    cliente: "Elena Castro",
    canal: "Tienda en línea",
    entrega: "Domicilio",
    estadoPago: "Pagado",
    total: 95.75,
    estadoPreparacion: "No preparado",
    fecha: "2024-03-17 11:10:22",
  },
  {
    pedido: "#010",
    cliente: "Javier Ramirez",
    canal: "Tienda física",
    entrega: "Domicilio",
    estadoPago: "Pagado",
    total: 55.0,
    estadoPreparacion: "No preparado",
    fecha: "2024-03-17 10:50:22",
  },
  {
    pedido: "#011",
    cliente: "Lucia Torres",
    canal: "Correo electrónico",
    entrega: "Domicilio",
    estadoPago: "Pendiente",
    total: 70.8,
    estadoPreparacion: "Pendiente",
    fecha: "2024-03-17 10:45:22",
  },
  {
    pedido: "#012",
    cliente: "Diego Ruiz",
    canal: "WhatsApp",
    entrega: "Local",
    estadoPago: "Pagado",
    total: 120.5,
    estadoPreparacion: "En preparación",
    fecha: "2024-03-17 10:44:22",
  },
  {
    pedido: "#013",
    cliente: "Marina Molina",
    canal: "Teléfono",
    entrega: "Local",
    estadoPago: "Pendiente",
    total: 85.25,
    estadoPreparacion: "Pendiente",
    fecha: "2024-03-17 10:42:22",
  },
  {
    pedido: "#014",
    cliente: "Pablo Navarro",
    canal: "Tienda en línea",
    entrega: "Domicilio",
    estadoPago: "Pagado",
    total: 40.75,
    estadoPreparacion: "No preparado",
    fecha: "2024-03-17 10:40:22",
  },
  {
    pedido: "#015",
    cliente: "Raquel Fernandez",
    canal: "Tienda física",
    entrega: "Domicilio",
    estadoPago: "Pagado",
    total: 60.0,
    estadoPreparacion: "No preparado",
    fecha: "2024-03-17 10:38:22",
  },
  {
    pedido: "#016",
    cliente: "Gonzalo Santos",
    canal: "Correo electrónico",
    entrega: "Domicilio",
    estadoPago: "Pendiente",
    total: 75.2,
    estadoPreparacion: "Pendiente",
    fecha: "2024-03-17 10:37:22",
  },
  {
    pedido: "#017",
    cliente: "Carolina Vargas",
    canal: "Teléfono",
    entrega: "Local",
    estadoPago: "Pendiente",
    total: 95.0,
    estadoPreparacion: "Pendiente",
    fecha: "2024-03-17 10:36:22",
  },
];

export const dataProductos = [
  {
    id: "#001",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Pizza Carbonara",
    category: "Pizza",
    catalog: "Carta Globo",
    price: 95.0,
    state: true,
  },
  {
    id: "#002",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Hamburguesa Clásica",
    category: "Hamburguesa",
    catalog: "Carta Globo",
    price: 85.0,
    state: false,
  },
  {
    id: "#003",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Pasta Alfredo",
    category: "Pasta",
    catalog: "Carta Globo",
    price: 75.0,
    state: true,
  },
  {
    id: "#004",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Ensalada César",
    category: "Ensalada",
    catalog: "Carta Globo",
    price: 65.0,
    state: false,
  },
  {
    id: "#005",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Sushi Variado",
    category: "Sushi",
    catalog: "Carta Globo",
    price: 105.0,
    state: false,
  },
  {
    id: "#006",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Poke Bowl",
    category: "Poke",
    catalog: "Carta Globo",
    price: 115.0,
    state: true,
  },
  {
    id: "#007",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Tacos Mexicanos",
    category: "Tacos",
    catalog: "Carta Globo",
    price: 90.0,
    state: true,
  },
  {
    id: "#008",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Sopa de Tomate",
    category: "Sopa",
    catalog: "Carta Globo",
    price: 60.0,
    state: true,
  },
  {
    id: "#009",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Parrilla Mixta",
    category: "Parrilla",
    catalog: "Carta Globo",
    price: 125.0,
    state: true,
  },
  {
    id: "#010",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Ramen de Pollo",
    category: "Ramen",
    catalog: "Carta Globo",
    price: 95.0,
    state: false,
  },
  {
    id: "#011",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Ceviche Peruano",
    category: "Ceviche",
    catalog: "Carta Globo",
    price: 100.0,
    state: false,
  },
  {
    id: "#012",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Fish and Chips",
    category: "Pescado",
    catalog: "Carta Globo",
    price: 80.0,
    state: true,
  },
  {
    id: "#013",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Pollo a la Brasa",
    category: "Pollo",
    catalog: "Carta Globo",
    price: 110.0,
    state: true,
  },
  {
    id: "#014",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Tarta de Queso",
    category: "Postre",
    catalog: "Carta Globo",
    price: 70.0,
    state: true,
  },
  {
    id: "#015",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Burrito Mexicano",
    category: "Burrito",
    catalog: "Carta Globo",
    price: 85.0,
    state: false,
  },
  {
    id: "#016",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Pad Thai",
    category: "Pad Thai",
    catalog: "Carta Globo",
    price: 90.0,
    state: true,
  },
  {
    id: "#017",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Sándwich Club",
    category: "Sándwich",
    catalog: "Carta Globo",
    price: 80.0,
    state: true,
  },
  {
    id: "#018",
    image: "https://t3.ftcdn.net/jpg/00/27/57/96/360_F_27579652_tM7V4fZBBw8RLmZo0Bi8WhtO2EosTRFD.jpg",
    name: "Tiramisú",
    category: "Postre",
    catalog: "Carta Globo",
    price: 75.0,
    state: true,
  },
];

export const dataSalesChannel = [
  {
    name: "Glovo",
    description: "Todas las frutas de temporada actual",
    schedule: "Entre semana / 08:00 - 17:00",
    productQuantity: 15,
    active: true,
  },
  { name: "Carta online", description: "Carta online de productos", schedule: "L-M-X-J-V / 08:00 - 17:00", productQuantity: 15, active: true },
  { name: "Local", description: "Local principal", schedule: "L-M-X-J-V / 08:00 - 14:00", productQuantity: 20, active: false },
  { name: "Tienda Calle Santiago", description: "Tienda", schedule: "L-M-X-J-V / 08:00 - 14:00", productQuantity: 20, active: true },
];

export const dataCategories = [
  {
    name: "Frutas",
    description: "Todas las frutas de temporada actual",
    productQuantity: 15,
    state: true,
  },
  { name: "Verduras", description: "Todas las verduras", productQuantity: 15, state: true },
  { name: "Carnes", description: "Carnes", productQuantity: 20, state: false },
  { name: "Bollería", description: "Bollería empaquetada", productQuantity: 20, state: true },
];

export const dataAttributes = [
  {
    name: "Seleccionar embalaje",
    description: "Lista de panes para seleccionar",
    options: 3,
    state: true,
  },
  {
    name: "Seleccionar embalaje",
    description: "Lista de panes para seleccionar",
    options: 3,
    state: true,
  },
];

export const dataClients = [
  {
    name: "Juan Perez",
    email: "juan.perez@example.com",
    phone: "555-1234",
    orders: 5,
  },
  {
    name: "Maria Lopez",
    email: "maria.lopez@example.com",
    phone: "555-5678",
    orders: 12,
  },
  {
    name: "Carlos García",
    email: "carlos.garcia@example.com",
    phone: "555-8765",
    orders: 3,
  },
  {
    name: "Ana Martinez",
    email: "ana.martinez@example.com",
    phone: "555-4321",
    orders: 8,
  },
  {
    name: "Luis Rodriguez",
    email: "luis.rodriguez@example.com",
    phone: "555-6543",
    orders: 10,
  },
];

export const dataDiscounts = [
  {
    discount: { name: "KBLS66578X", description: "15% de descuento en todo el pedido" },
    active: true,
    type: "Porcentaje",
    used: 10,
  },
  {
    discount: { name: "YUIO99876Z", description: "20% de descuento en artículos seleccionados" },
    active: true,
    type: "Porcentaje",
    used: 15,
  },
  {
    discount: { name: "JHGF43567L", description: "10% de descuento en la primera compra" },
    active: true,
    type: "Porcentaje",
    used: 5,
  },
  {
    discount: { name: "QWER12345T", description: "25% de descuento en pedidos superiores a $50" },
    active: false,
    type: "Porcentaje",
    used: 20,
  },
  {
    discount: { name: "ASDF67890M", description: "30% de descuento en productos electrónicos" },
    active: true,
    type: "Porcentaje",
    used: 25,
  },
  {
    discount: { name: "ZXCV11223N", description: "5% de descuento en pedidos en línea" },
    active: true,
    type: "Porcentaje",
    used: 8,
  },
  {
    discount: { name: "BNML22334V", description: "15% de descuento en productos para el hogar" },
    active: false,
    type: "Porcentaje",
    used: 18,
  },
  {
    discount: { name: "LKJH98765B", description: "20€ de descuento en ropa y accesorios" },
    active: true,
    type: "Valor",
    used: 30,
  },
  {
    discount: { name: "POIU55432C", description: "10€ de descuento en compras superiores a $100" },
    active: true,
    type: "Valor",
    used: 12,
  },
  {
    discount: { name: "MNBV77665X", description: "15€ de descuento en todo el sitio web" },
    active: true,
    type: "Valor",
    used: 22,
  },
  {
    discount: { name: "QWAS09876D", description: "25% de descuento en artículos de temporada" },
    active: false,
    type: "Porcentaje",
    used: 27,
  },
];
