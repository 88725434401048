import React, { useState } from "react";
import { dataOrdersSalesChannel } from "./../../../components/datatable/mock";
import { Datatable } from "./../../../components/datatable";
import { StateLabel } from "../../../components/stateLabel";
import { Button } from "../../../components/button";
import "./style.css";

export const Shop = () => {
  const [orderDetailsModal, setOrderDetailsModal] = useState(null);
  // =========== Datatable Configuration ============
  const sections = [{ text: null, callback: () => null, actions: ["search"], actionsWithSelect: [""] }];
  const customHeaders = {
    pedido: "No de pedido",
    description: "Descripción",
    options: "Opciones",
    estadoPago: "Estado",
  };
  const customData = {
    estadoPago: (data) => <StateLabel state={data} />,
  };
  // ================================================

  const FloatModal = ({ content }) => {
    return (
      <>
        <div className="shadowBackground">{content}</div>
      </>
    );
  };

  const OrderDetails = () => {
    const Item = ({ data }) => {
      return (
        <div className="item-order-sales">
          <div className="item-order-sales-info">
            <div className="item-order-sales-info-image"></div>
            <div className="item-order-sales-info-description">
              <div>
                <b>Manzana roja</b>
              </div>
              <div>Atributos</div>
            </div>
          </div>

          <div className="item-order-sales-data">
            <div className="item-order-sales-data-quantity">x1</div>
            <div className="item-order-sales-data-total">
              <b>24,99€</b>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="orderDetailsSalesChannel">
        <div className="order-detail-sales-header">
          <div className="order-detail-sales">
            <StateLabel state={3} />
            <div className="order-detail-sales-info">
              <div>No. Pedido</div>
              <div>
                <b>31542-F1</b>
              </div>
            </div>
          </div>
          <div className="order-detail-sales-action">
            <Button text={"Cobrar"} icon={null} customClass={"customButtonColor2"} action={() => setOrderDetailsModal(null)} />
            <Button text={"Cerrar"} icon={null} customClass={"customButtonColor1"} action={() => setOrderDetailsModal(null)} />
          </div>
        </div>

        <div className="order-detail-sales-details">
          <div>Fecha del pedido</div>
          <div>
            <b>05 de julio de 2024</b>
          </div>
        </div>

        <div className="order-detail-sales-itemList">
          {[0, 1, 2, 3].map((item) => (
            <Item data={item} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <Datatable
        data={dataOrdersSalesChannel}
        customHeaders={customHeaders}
        customData={customData}
        sections={sections}
        rowCallback={setOrderDetailsModal}
      />
      {orderDetailsModal !== null && <FloatModal content={<OrderDetails />} />}
    </>
  );
};
