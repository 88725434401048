import "./style.css";

export const StateLabel = ({ state }) => {
  const Label = () => {
    switch (state) {
      case 1:
        return <div className="state-label state-delivered">Entregado</div>;
      case 2:
        return <div className="state-label state-canceled">Cancelado</div>;
      case 3:
        return <div className="state-label state-pending">Pendiente</div>;
      case 4:
        return <div className="state-label state-delivered">Pagado</div>;
      default:
        return <div>Cancelado</div>;
    }
  };
  return <Label />;
};
