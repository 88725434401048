import React, { useState } from "react";
//Components
import { LuCheck, LuMinus, LuPlus, LuX } from "react-icons/lu";
import { BsThreeDots, BsPrinter } from "react-icons/bs";
import { RiDiscountPercentLine } from "react-icons/ri";
import { MdKeyboardArrowUp, MdEditNote } from "react-icons/md";
import { GoCreditCard } from "react-icons/go";
import { AiOutlineEuro } from "react-icons/ai";
import { Header } from "../header";
import { Select } from "./../../components/select";
import { Input } from "./../../components/inputs/type1";
import { Button } from "../../components/button";
import { Textarea } from "../../components/textarea/type1";
import "./style.css";

export const Commander = () => {
  const [cart, updateCart] = useState([]);
  const [showCartOptions, setShowCartOptions] = useState(false);
  const [showCartPayments, setShowCartPayments] = useState(false);
  const [paymentAccepted, setPaymentAccepted] = useState(false);
  const [productDetailsModal, setProductDetailsModal] = useState(null);

  const categoriesMock = [
    { id: 0, name: "Bebidas", img: "https://www.mollinn.com/img/default-product-img.jpg" },
    { id: 1, name: "Hamburguesas", img: "https://www.mollinn.com/img/default-product-img.jpg" },
    { id: 2, name: "Pizzas", img: "https://www.mollinn.com/img/default-product-img.jpg" },
    { id: 3, name: "Cafés", img: "https://www.mollinn.com/img/default-product-img.jpg" },
  ];
  const productsMock = [
    { id: 0, name: "Clásica", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 25 },
    { id: 1, name: "Doble", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 20 },
    { id: 2, name: "Picante", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 15 },
    { id: 3, name: "BBQ", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 20 },
    { id: 4, name: "Clásica Premium", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 25 },
    { id: 5, name: "Doble Premium", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 20 },
    { id: 6, name: "Picante Premium", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 15 },
    { id: 7, name: "BBQ Premium", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 20 },
    { id: 8, name: "BBQ Extra Queso", img: "https://www.mollinn.com/img/default-product-img.jpg", price: 15 },
  ];

  const FloatModal = ({ content }) => {
    return (
      <>
        <div className="shadowBackground">{content}</div>
      </>
    );
  };

  const PaymentAccepted = () => {
    return (
      <div className="paymentAccepted">
        <AiOutlineEuro />
        <div>
          <b>Pago completado</b>
        </div>

        <Button text={"Nuevo pedido"} icon={null} customClass={"customButtonColor2"} action={() => setPaymentAccepted(!paymentAccepted)} />

        <Button text={"Imprimir recibo"} icon={null} customClass={"customButtonColor1"} action={() => null} />
      </div>
    );
  };

  const ProductDetails = () => {
    return (
      <div className="productDetails">
        <div className="categoryItemImg">
          <img src={productDetailsModal.img} alt="logo" className="brandDashboard" />
        </div>
        <div>
          <b>{productDetailsModal.name}</b>
        </div>
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Vivamus laoreet, sapien eget cursus interdum, justo mi dictum
          metus, id auctor ipsum nunc vel odio.
        </div>

        <div className="productDetails-block">
          <div className="productDetails-block-title">Variantes</div>
          <div className="radio-item">
            <input type="radio" name="variant" />
            <label> Peperoni</label>
          </div>
          <div className="radio-item">
            <input type="radio" name="variant" />
            <label> Peperoni y champiñones</label>
          </div>
        </div>

        <div className="productDetails-block">
          <div className="productDetails-block-title">Añadir una nota</div>
          <Textarea id={"product-details-notes"} text={""} />
        </div>

        <div className="productItemInfo">
          <div>{productDetailsModal.price.toFixed(2)} €</div>
          <div>
            <LuPlus onClick={() => addCart(productDetailsModal)} />
          </div>
        </div>

        <Button text={"Cerrar"} icon={null} customClass={"customButtonColor2"} action={() => setProductDetailsModal(null)} />
      </div>
    );
  };

  const addCart = (newItem) => {
    updateCart((prevItems) => {
      const existingItem = prevItems.find((item) => item.id === newItem.id);

      if (existingItem) {
        // Si el producto ya está en el carrito, incrementa la cantidad
        return prevItems.map((item) => (item.id === newItem.id ? { ...item, quantity: item.quantity + 1 } : item));
      } else {
        // Si el producto no está en el carrito, agrégalo con quantity: 1
        return [...prevItems, { ...newItem, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (itemToRemove) => {
    updateCart((prevItems) => {
      const existingItem = prevItems.find((item) => item.id === itemToRemove.id);

      if (existingItem) {
        if (existingItem.quantity > 1) {
          // Si el producto tiene más de 1 en cantidad, reduce la cantidad en 1
          return prevItems.map((item) => (item.id === itemToRemove.id ? { ...item, quantity: item.quantity - 1 } : item));
        } else {
          // Si el producto tiene solo 1 en cantidad, lo elimina del carrito
          return prevItems.filter((item) => item.id !== itemToRemove.id);
        }
      }
      return prevItems; // Si el producto no está en el carrito, no hacer nada
    });
  };

  const getTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const CartOptions = () => {
    return (
      <div className="cartOptions">
        <BsPrinter /> <RiDiscountPercentLine /> <MdEditNote />
      </div>
    );
  };

  const CartPayments = () => {
    const [paymentCard, setPaymentCard] = useState(false);
    const [paymentMoney, setPaymentMoney] = useState(false);
    const [paymentBizum, setPaymentBizum] = useState(false);
    const [inputCard, setInputCard] = useState(0);
    const [inputMoney, setInputMoney] = useState(0);
    const [inputBizum, setInputBizum] = useState(0);

    const Card = ({ data }) => {
      let cardUserSelected = "";
      if (data.name === "Tarjeta" && paymentCard === true) cardUserSelected = "cardUserSelected";
      if (data.name === "Efectivo" && paymentMoney === true) cardUserSelected = "cardUserSelected";
      if (data.name === "Bizum" && paymentBizum === true) cardUserSelected = "cardUserSelected";
      return (
        <div className={"cardUser " + cardUserSelected} onClick={data.callback}>
          <div className="userIcon">{data.icon}</div>
          <div className="username">{data.name}</div>
        </div>
      );
    };

    const InputQuantity = ({ data }) => {
      let paymentSelected = false;
      if (data.name === "Tarjeta" && paymentCard === true) paymentSelected = true;
      if (data.name === "Efectivo" && paymentMoney === true) paymentSelected = true;
      if (data.name === "Bizum" && paymentBizum === true) paymentSelected = true;
      return (
        paymentSelected && (
          <div className="cardUserQuantity">
            <div className="username">{data.name}</div>
            <div>
              <Input
                id={"paymentCardInput"}
                type={"text"}
                placeholder=""
                disabled={""}
                onBlur={(e) => data.callback(e.target.value)}
                icon={null}
                iconType={""}
                classname={""}
                defaultValue={data.value}
              />
            </div>
          </div>
        )
      );
    };

    const Resume = ({ data }) => {
      return (
        <div className="cardUser">
          <div className="cardUser-title">{data.name}</div>
          <div className="cardUser-value">{data.value}</div>
        </div>
      );
    };

    const getFaltante = () => {
      const total = getTotalPrice() + getTotalPrice() * 0.21;
      const recibido = parseFloat(inputCard) + parseFloat(inputMoney) + parseFloat(inputBizum);
      if (total > recibido) return recibido - total;
      else return 0;
    };

    const getCambio = () => {
      const total = getTotalPrice() + getTotalPrice() * 0.21;
      const recibido = parseFloat(inputCard) + parseFloat(inputMoney) + parseFloat(inputBizum);
      if (total < recibido) return recibido - total;
      else return 0;
    };

    return (
      <div className="cartPayments">
        <div></div>
        <div className="cartPayments-modal">
          <div className="cartPayments-close" onClick={() => setShowCartPayments(!showCartPayments)}>
            <div className="cartPayments-close-action"></div>
          </div>
          <div className="cartPayments-title">Seleccione métodos de pago</div>

          <div className="cartPayments-list">
            <Card data={{ icon: <GoCreditCard />, name: "Tarjeta", callback: () => setPaymentCard(!paymentCard) }} />
            <Card data={{ icon: <GoCreditCard />, name: "Efectivo", callback: () => setPaymentMoney(!paymentMoney) }} />
            <Card data={{ icon: <GoCreditCard />, name: "Bizum", callback: () => setPaymentBizum(!paymentBizum) }} />
          </div>

          <div className="cartPayments-list">
            <InputQuantity data={{ name: "Tarjeta", callback: setInputCard, value: inputCard }} />
            <InputQuantity data={{ name: "Efectivo", callback: setInputMoney, value: inputMoney }} />
            <InputQuantity data={{ name: "Bizum", callback: setInputBizum, value: inputBizum }} />
          </div>

          <div className="cartPayments-title">Resumen</div>

          <div className="cartPayments-list">
            <Resume data={{ name: "Total", value: (getTotalPrice() + getTotalPrice() * 0.21).toFixed(2) + "€" }} />
            <Resume data={{ name: "Recibido", value: (parseFloat(inputCard) + parseFloat(inputMoney) + parseFloat(inputBizum)).toFixed(2) + "€" }} />
            <Resume data={{ name: "Faltante", value: getFaltante().toFixed(2) + "€" }} />
            <Resume data={{ name: "Cambio", value: getCambio().toFixed(2) + "€" }} />
          </div>
        </div>
      </div>
    );
  };

  const CategoryItem = ({ data }) => {
    return (
      <div className="categoryItem">
        <div className="categoryItemImg">
          <img src={data.img} alt="logo" className="brandDashboard" />
        </div>
        <div className="categoryItemName">{data.name}</div>
      </div>
    );
  };

  const ProductItem = ({ data }) => {
    return (
      <div className="productItem">
        <div className="productItemImg" onClick={() => setProductDetailsModal(data)}>
          <img src={data.img} alt="logo" className="brandDashboard" />
        </div>
        <div className="productItemName">{data.name}</div>
        <div className="productItemInfo">
          <div>{data.price} €</div>
          <div>
            <LuPlus onClick={() => addCart(data)} />
          </div>
        </div>
      </div>
    );
  };

  const Order = () => {
    const [searchMode, setSearchMode] = useState(false);
    return (
      <div className="order-commander-container">
        <div className="order-commander-header">
          <div className="order-commander-header-row">
            <div className="order-commander-header-leftCol">
              <div>
                <b>Pedido: 35245-2B</b>
              </div>
              <div>Operador de caja: Alberto</div>
            </div>
            <div className="order-commander-header-rightCol">
              <Select values={[{ id: 0, name: "Domicilio" }]} callback={() => null} />
            </div>
          </div>
          {!searchMode ? (
            <div className="order-commander-header-row">
              <div className="order-commander-header-leftCol">
                <div>Cliente:</div>
                <div>
                  <b>Predeterminado</b>
                </div>
              </div>
              <div className="order-commander-header-rightCol squareSvg">
                <LuPlus onClick={() => setSearchMode(true)} />
              </div>
            </div>
          ) : (
            <div className="order-commander-header-row">
              <div className="order-commander-header-leftCol">
                <Input
                  id={"searchClientInput"}
                  type={"text"}
                  placeholder=""
                  disabled={""}
                  onBlur={(e) => null}
                  icon={null}
                  iconType={""}
                  classname={""}
                  defaultValue={""}
                />
              </div>
              <div className="order-commander-header-rightCol  squareSvg">
                <LuCheck onClick={() => null} />
                <LuPlus onClick={() => null} />
                <LuX onClick={() => setSearchMode(false)} />
              </div>
            </div>
          )}
        </div>
        <div className="order-commander-product-list">
          <div>
            <b>Productos añadidos</b>
          </div>
          <div className="itemCart-list">
            {cart.map((item) => (
              <div className="itemCart">
                <div className="itemCart-info">
                  <div className="itemCart-actions">
                    <LuMinus onClick={() => removeFromCart(item)} /> <div>{item.quantity}</div> <LuPlus onClick={() => addCart(item)} />
                  </div>
                  <div>
                    <div className="itemCart-name">{item.name}</div>
                    <div className="itemCart-description">Cebolla y pan brioche</div>
                  </div>
                </div>
                <div className="itemCart-price">{item.price * item.quantity} €</div>
              </div>
            ))}
          </div>
        </div>
        <div className="order-commander-totals">
          <div className="order-commander-totals-container">
            <div className="order-commander-totals-row">
              <div className="order-commander-total-row-label">Subtotal</div>
              <div className="order-commander-total-row-info">{getTotalQuantity()} artículos</div>
              <div className="order-commander-total-row-value">{getTotalPrice().toFixed(2)} €</div>
            </div>
            <div className="order-commander-totals-row">
              <div className="order-commander-total-row-label">Domicilio</div>
              <div className="order-commander-total-row-info"> </div>
              <div className="order-commander-total-row-value">0.00 €</div>
            </div>
            <div className="order-commander-totals-row">
              <div className="order-commander-total-row-label">Descuento</div>
              <div className="order-commander-total-row-info"> </div>
              <div className="order-commander-total-row-value">0.00 €</div>
            </div>
            <div className="order-commander-totals-row">
              <div className="order-commander-total-row-label">Impuestos</div>
              <div className="order-commander-total-row-info"> 21% de IVA</div>
              <div className="order-commander-total-row-value">{(getTotalPrice() * 0.21).toFixed(2)} €</div>
            </div>
          </div>
          <div className="order-commander-totals-resume" onClick={() => setShowCartPayments(!showCartPayments)}>
            <div className="order-commander-totals-resume-label">Total</div>
            <div className="order-commander-totals-resume-value">
              {(getTotalPrice() + getTotalPrice() * 0.21).toFixed(2)}€ <MdKeyboardArrowUp />
            </div>
          </div>
        </div>
        <div className="order-commander-action">
          <div className="order-commander-action-options">
            <BsThreeDots onClick={() => setShowCartOptions(!showCartOptions)} />
            {showCartOptions && <CartOptions />}
          </div>
          <div className="order-commander-action-pay" onClick={() => setPaymentAccepted(!paymentAccepted)}>
            Cobrar
          </div>
        </div>
        {showCartPayments && <CartPayments />}
      </div>
    );
  };

  const Body = () => {
    return (
      <div className="commander-body">
        <div className="categories">
          <div className="body-header">Categorías</div>
          <div className="categories-body-content">
            {categoriesMock.map((item) => (
              <CategoryItem data={item} />
            ))}
          </div>
        </div>
        <div className="products">
          <div className="body-header">Productos</div>
          <div className="products-body-content">
            {productsMock.map((item) => (
              <ProductItem data={item} />
            ))}
          </div>
        </div>
        <div className="order">
          <div className="body-header">Orden</div>
          <div className="order-commander-body-content">
            <Order />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Body />
      {paymentAccepted === true && <FloatModal content={<PaymentAccepted />} />}
      {productDetailsModal !== null && <FloatModal content={<ProductDetails />} />}
    </>
  );
};
