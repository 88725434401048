import React from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch } from "react-redux";
//Components
import { MdOutlineFacebook } from "react-icons/md";
import { Button } from "../../../../components/button";

export const Facebook = () => {
  const dispatch = useDispatch();
  //const navigate = useNavigate();

  return <Button type={"outlined"} text={"Iniciar sesión con Facebook"} icon={<MdOutlineFacebook />} customClass={"color-blue"} />;
};
