import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setComponentActive, setUserLogOut } from "../../reducers/userConfigSlice";

import { HiLogout } from "react-icons/hi";
import { VscHistory } from "react-icons/vsc";
import { FiShoppingBag } from "react-icons/fi";
import { BsShopWindow } from "react-icons/bs";
import Logo from "./../../assets/logo/logo-delibran.svg";
import "./style.css";

export const Header = () => {
  const dispatch = useDispatch();
  const componentActive = useSelector((state) => state.aplicationConfig.userConfig.componentActive);

  const logOff = async () => {
    dispatch(setUserLogOut());
  };

  const handleMenu = (component) => {
    dispatch(setComponentActive(component));
  };

  return (
    <div className="commander-header">
      <div className="logo">
        <img src={Logo} alt="logo" className="brandDashboard" />
      </div>
      <div className="menu">
        <VscHistory onClick={() => handleMenu("history")} className={componentActive === "history" && "menuSelected"} />
        <BsShopWindow onClick={() => handleMenu("salesChannel")} className={componentActive === "salesChannel" && "menuSelected"} />
        <FiShoppingBag onClick={() => handleMenu("commander")} className={componentActive === "commander" && "menuSelected"} />
        <HiLogout className="color_red" onClick={logOff} />
      </div>
    </div>
  );
};
